import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { If } from './If';
import SimpleForm from './SimpleForm';
import DikiHistory from './DikiHistory';
import { Button } from 'antd';
import {
  SettingFilled,
  RollbackOutlined,
  ForwardOutlined,
  CheckCircleFilled,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { makeCall } from '../utils';
import * as config from '../config';

const tabComponent = (n) =>
  <span dangerouslySetInnerHTML={{ __html: '&nbsp;'.repeat(4).repeat(n) }}></span>;

function Card() {
  const { id: cardId } = useParams();
  const navigate = useNavigate();

  const [thing, setThing] = useState(null);
  const [processed, setProcessed] = useState(0);
  const [active, setActive] = useState(0);
  const [mode, setMode] = useState('default');
  const [loading, setLoading] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [payload, setPayload] = useState([]);
  const [isDikiPreview, setIsDikiPreview] = useState(false);
  const [dikiPreviewData, setDikiPreviewData] = useState({});

  const loadNextThing = async () => {
    if (!cardId) return;
    const response = await makeCall('GET', null, `/next/${cardId}`);
    const { thing, processed, active, audioUrl, payload } = response;
    const _payload = payload ? payload.words : [];
    const mode = thing.translations.length === 0 ? 'edit' : 'default';

    setThing(thing);
    setProcessed(processed);
    setActive(active);
    setAudioUrl(audioUrl);
    setPayload(_payload);
    setMode(mode);
  };

  const acceptThing = async (cardId, thingId, isProcessed = false) => {
    setLoading(true);
    setMode('default');
    const body = JSON.stringify({ cardId, thingId, isProcessed });
    const resp = await makeCall('POST', body, `/next`);
    const { thing, processed, active, audioUrl, payload } = resp;
    const _payload = payload ? payload.words : [];
    const mode = thing.translations.length === 0 ? 'edit' : 'default';

    setThing(thing);
    setProcessed(processed);
    setActive(active);
    setAudioUrl(audioUrl);
    setPayload(_payload);
    setMode(mode);
    setLoading(false);
    await playAudio(thing.word);
  };

  const playAudio = async (word) => {
    if (word) {
      const audio = new Audio(`${config.baseUrl}/audio/ang/${word}.mp3`);
      await audio.play();
    }
  };

  const deleteThing = async (cardId, thingId) => {
    const body = JSON.stringify({ type: 'thing', cardId, thingId });
    await makeCall('DELETE', body);
    await loadNextThing();
  };

  const updateThingName = async (cardId, thingId, word) => {
    setMode('default');
    const body = JSON.stringify({ type: 'thing', cardId, thingId, word });
    await makeCall('GET', body);
    await loadNextThing();
  };

  const addTranslation = async (cardId, thingId, name) => {
    const body = JSON.stringify({ type: 'translation', cardId, thingId, name });
    await makeCall('PUT', body);
    await loadNextThing();
  };

  const addSentence = async (cardId, thingId, translationId, sentence) => {
    const body = JSON.stringify({ type: 'sentence', cardId, thingId, translationId, sentence });
    await makeCall('PUT', body);
    await loadNextThing();
  };

  const deleteTranslation = async (cardId, thingId, translationId) => {
    const body = JSON.stringify({ type: 'translation', cardId, thingId, translationId });
    await makeCall('DELETE', body);
    await loadNextThing();
  };

  const deleteSentence = async (cardId, thingId, translationId, sentenceId) => {
    const body = JSON.stringify({ type: 'sentence', cardId, thingId, translationId, sentenceId });
    await makeCall('DELETE', body);
    await loadNextThing();
  };

  const openInDiki = (word) => {
    window.open(`https://www.diki.pl/slownik-angielskiego?q=${word}`, '_blank');
  };

  const showDikiView = (cardId, thingId, translationId, translationFor) => {
    setIsDikiPreview(true);
    setDikiPreviewData({ cardId, thingId, translationId, translationFor });
  };

  const closeDikiPreview = () => {
    setIsDikiPreview(false);
    setDikiPreviewData({});
  };

  const showDikiHistory = () => {
    setMode((prev) => (prev === 'diki-history' ? 'default' : 'diki-history'));
  };

  const onAddThing = async (word) => {
    const body = JSON.stringify({ type: 'thing', cardId, word });
    await makeCall('PUT', body);
    await loadNextThing();
  };

  const getDikiPreview = () => {
    const words = payload || [];
    const { cardId, thingId, translationId, translationFor } = dikiPreviewData;

    const handleClick = async (sentence) => {
      await addSentence(cardId, thingId, translationId, sentence);
    };

    const result = [];

    for (const word of words) {
      result.push(<div key={word.name}><b>{word.name}</b>{word.otherNames ? ', ' + word.otherNames.join(',') : ''}</div>);
      if (word.addition) result.push(<div key={`${word.name}-add`}>{word.addition}</div>);

      for (const tran of word.translations || []) {
        result.push(
          <div key={`${word.name}-tran`} style={{ display: 'flex', flexDirection: 'row' }}>
            ⚫ {tran.translation.map((t, i) => (
              <div key={i} className="diki-translation" onClick={() => handleClick(t)}>
                {t + ', '}
              </div>
            ))}
          </div>
        );
        for (const sentence of tran.sentences || []) {
          result.push(
            <div
              key={sentence.value}
              className="diki-sentence"
              onClick={() => handleClick(sentence.value)}
            >
              {tabComponent(2)}{' ' + sentence.value}
            </div>
          );
        }
      }

      for (const speech of word.speeches || []) {
        result.push(<div key={speech.speechName}>{speech.speechName}</div>);
        for (const tran of speech.translations) {
          result.push(
            <div key={tran.translation.join(',')} style={{ display: 'flex', flexDirection: 'row' }}>
              ⚫ {tran.translation.map((t, i) => (
                <div key={i} className="diki-translation" onClick={() => handleClick(t)}>
                  {t + ', '}
                </div>
              ))}
            </div>
          );
          for (const sentence of tran.sentences || []) {
            result.push(
              <div
                key={sentence.value}
                className="diki-sentence"
                onClick={() => handleClick(sentence.value)}
              >
                {tabComponent(2)}{' ' + sentence.value}
              </div>
            );
          }
          for (const t of tran.trans || []) {
            result.push(
              <div key={t} onClick={() => handleClick(t)}>
                {tabComponent(1)}{'⚪ ' + t}
              </div>
            );
          }
        }
      }
    }

    return (
      <div>
        <div className="translation-for">{translationFor}</div>
        {result}
      </div>
    );
  };

  useEffect(() => {
    loadNextThing();
  }, [cardId]);

  const isEditMode = mode === 'edit';

  if (!thing) {
    return 'Empty';
  }


  return (
    <div className="container">
      <div className="card-container">
        <If cond={thing !== null}>
          <If cond={!isDikiPreview}>
            <div className="card card-more">
              <div className="thing-view-header">
                <If cond={!isEditMode}>
                  <div onClick={() => playAudio(thing.word)} className="thing-title">{thing.word}</div>
                </If>
                <If cond={isEditMode}>
                  <SimpleForm
                    onClick={(v) => updateThingName(cardId, thing.id, v)}
                    value={thing.word}
                    withDikiContent
                    content={<div />}
                  />
                </If>
                <div className="card-actions">
                  <If cond={isEditMode}>
                    <div className="action delete-action" onClick={() => deleteThing(cardId, thing.id)}>x</div>
                  </If>
                  <Button type="link" icon={<SettingFilled />} size="large" onClick={() => setMode((m) => (m === 'edit' ? 'default' : 'edit'))} />
                  <Button type="link" icon={<img
                    src="https://www.diki.pl/images/diki/diki_logo.svg"
                    alt="diki"
                    style={{ width: '1em', height: '1em', objectFit: 'contain' }}
                  />} size='small' onClick={() => openInDiki(thing.word)} />
                  <Button type="link" icon={<ArrowRightOutlined />} size="large" onClick={showDikiHistory} />
                </div>
              </div>

              <div className="thing-translations">
                <If cond={isEditMode}>
                  <SimpleForm
                    className="add-action"
                    payload={payload}
                    withDikiContent
                    onClick={(v) => addTranslation(cardId, thing.id, v)}
                    content="+"
                  />
                </If>
                {thing.translations.map((translation) => (
                  <div key={translation.id} className="translation">
                    <div className="translations-header">
                      <If cond={isEditMode}>
                        <div className="action delete-action" onClick={() => deleteTranslation(cardId, thing.id, translation.id)}>x</div>
                      </If>
                      <div onClick={() => showDikiView(cardId, thing.id, translation.id, translation.name)}>
                        {translation.name}
                      </div>
                    </div>
                    <If cond={isEditMode}>
                      <SimpleForm
                        className="add-action"
                        onClick={(v) => addSentence(cardId, thing.id, translation.id, v)}
                        payload={payload}
                        withDikiContent
                        content="+"
                      />
                    </If>
                    {translation.sentences.map((sentence) => (
                      <div key={sentence.id} className="sentence">
                        <div className="sentences-header">
                          <If cond={isEditMode}>
                            <div className="action delete-action" onClick={() => deleteSentence(cardId, thing.id, translation.id, sentence.id)}>x</div>
                          </If>
                          {sentence.sentence}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className="thing-footer">
                <Button
                  type="primary"
                  icon={<RollbackOutlined />}
                  size="large"
                  onClick={() => navigate(-1)}
                  style={{ height: 'auto' }}
                />
                <div className="indicator">{processed}</div>
                <Button
                  type="primary"
                  icon={<CheckCircleFilled />}
                  size="large"
                  onClick={() => acceptThing(cardId, thing.id, true)}
                  style={{ marginRight: '3px', width: '60px' }}
                  disabled={active === 0 || loading}
                  loading={loading}
                />
                <Button
                  type="primary"
                  icon={<ForwardOutlined />}
                  size="large"
                  onClick={() => acceptThing(cardId, thing.id)}
                  style={{ flex: 1 }}
                  disabled={active === 0 || active === 1 || loading}
                  loading={loading}
                />
                <div className="indicator"><span>{active}</span></div>
              </div>
            </div>
          </If>

          <If cond={isDikiPreview}>
            <div className="card">
              <div className="thing-translations">{getDikiPreview()}</div>
              <div className="thing-footer">
                <Button
                  type="primary"
                  icon={<RollbackOutlined />}
                  size="large"
                  onClick={closeDikiPreview}
                  style={{ height: 'auto' }}
                />
              </div>
            </div>
          </If>
        </If>

        <If cond={mode === 'diki-history'}>
          <DikiHistory onAddThing={onAddThing} />
        </If>
      </div>
    </div>
  );
}

export default Card;
