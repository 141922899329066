import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ForwardOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

function Settings() {
  const [settings, setSettings] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedSettings = localStorage.getItem('settings') || '';
    setSettings(storedSettings);
  }, []);

  const onSaveSettings = async () => {
    navigate('/');
  };

  return (
    <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        type="primary"
        icon={<RollbackOutlined />}
        size="large"
        onClick={() => navigate(-1)}
        style={{ height: 'auto' }}
      />
      <div style={{ display: 'flex', flex: 1 }}>
        <input
          value={settings}
          onChange={(e) => setSettings(e.target.value)}
          style={{ backgroundColor: '#272626', color: 'white', flex: 1 }}
        />
        <Button
          type="primary"
          icon={<ForwardOutlined />}
          size="large"
          onClick={onSaveSettings}
          style={{ height: 'auto' }}
        />
      </div>
    </div>
  );
}

export default Settings;
