import React, { useState, useEffect } from 'react';
import { makeCall } from '../utils';

function DikiHistory({ onAddThing }) {
  const [words, setWords] = useState([]);

  useEffect(() => {
    const fetchWords = async () => {
      const result = await makeCall('GET', null, '/services/diki-history');
      setWords(result);
    };

    fetchWords();
  }, []);

  return (
    <div className='diki-history'>
      {words.map((word) => (
        <div
          key={word}
          className='diki-history-item'
          onClick={() => onAddThing(word)}
        >
          {word}
        </div>
      ))}
    </div>
  );
}

export default DikiHistory;
