import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import {
  PlayCircleOutlined,
  ImportOutlined,
  SettingFilled,
  MenuOutlined,
} from '@ant-design/icons';

import SimpleForm from './SimpleForm';
import { makeCall, XYZA_HEADER } from '../utils';

function Cards() {
  const [cards, setCards] = useState([]);
  const [mode, setMode] = useState('default');
  const [loading, setLoading] = useState(true);
  const [notAuthenticated, setNotAuthenticated] = useState(false);
  const [xyzaValue, setXyzaValue] = useState('');
  const navigate = useNavigate();

  const loadCards = async () => {
    setLoading(true);
    const cards = await makeCall('GET', null, '');
    if (cards.notAuthenticated) {
      setNotAuthenticated(true);
      setLoading(false);
    } else {
      setCards(cards);
      setLoading(false);
    }
  };

  const addCard = async (name) => {
    setLoading(true);
    const body = JSON.stringify({ type: 'card', name });
    await makeCall('PUT', body);
    await loadCards();
  };

  const deleteCard = async (cardId) => {
    setLoading(true);
    const body = JSON.stringify({ type: 'card', cardId });
    await makeCall('DELETE', body);
    await loadCards();
  };

  const toggleMode = () => {
    setMode(prev => (prev === 'edit' ? 'default' : 'edit'));
  };

  const addThing = async (cardId, word) => {
    setLoading(true);
    const body = JSON.stringify({ type: 'thing', cardId, word });
    await makeCall('PUT', body);
    await loadCards();
  };

  const updateCardName = async (cardId, name) => {
    setLoading(true);
    const body = JSON.stringify({ type: 'card', cardId, name });
    await makeCall('POST', body);
    await loadCards();
    setMode('default');
  };

  const approveXyza = () => {
    localStorage.setItem(XYZA_HEADER, xyzaValue);
    loadCards();
  };

  useEffect(() => {
    loadCards();
  }, []);

  return (
    <div className="container">
      <div className="top-panel">
        <Link to={`/import`}>
          <Button type="link" icon={<ImportOutlined />} size="large" />
        </Link>
        <SimpleForm
          className="add-action"
          onClick={(v) => addCard(v)}
          content="+"
          disabled={loading}
        />
        <Button type="link" icon={<SettingFilled />} size="large" onClick={toggleMode} />
        <Link to={`/settings`}>
          <Button type="link" icon={<MenuOutlined />} size="large" />
        </Link>
        Version: 1
      </div>

      <div className={`cards-container${loading ? ' container--loading' : ''}`}>
        {notAuthenticated && (
          <div>
            <input onChange={(e) => setXyzaValue(e.target.value)} value={xyzaValue} />
            <button onClick={approveXyza}>Approve</button>
          </div>
        )}

        {cards.map(card => (
          <div className="card card-compact" key={card.id}>
            <div className="card-body">
              <div className="card-header">
                <div className="card-title">
                  {mode === 'edit' ? (
                    <SimpleForm
                      className="add-action"
                      onClick={(v) => updateCardName(card.id, v)}
                      content="+"
                      value={card.name}
                      disabled={loading}
                    />
                  ) : (
                    card.name
                  )}
                </div>
              </div>

              <SimpleForm
                className="add-action"
                onClick={(v) => addThing(card.id, v)}
                content="+"
                disabled={loading}
              />
            </div>

            <div className="card-footer">
              <Link to={`/${card.id}`}>
                <Button type="link" icon={<PlayCircleOutlined />} size="large" />
              </Link>
              {mode === 'edit' && (
                <div className="action delete-action" onClick={() => deleteCard(card.id)}>
                  x
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;
