import React from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';

import Cards from './components/Cards';
import Card from './components/Card';
import ImportView from './components/ImportView';
import Settings from './components/Settings';

function AppRoutes() {
  const routes = useRoutes([
    { path: '/import', element: <ImportView /> },
    { path: '/settings', element: <Settings /> },
    { path: '/', element: <Cards /> },
    { path: '/:id', element: <Card /> },
    { path: '*', element: <div>NOT FOUND</div> },
  ]);

  return routes;
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;