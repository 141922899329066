import React, { useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { If } from './If';

const tabComponent = (n) =>
  <span dangerouslySetInnerHTML={{ __html: '&nbsp;'.repeat(4).repeat(n) }}></span>;

function SimpleForm({
  value: initialValue = '',
  onClick,
  children,
  content,
  className,
  withDikiContent,
  payload = [],
  disabled = false,
}) {
  const [value, setValue] = useState(initialValue);
  const [previewActive, setPreviewActive] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClick = () => {
    if (!value) return;
    onClick(value);
    setValue('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  const toggleDikiContent = () => {
    setPreviewActive((prev) => !prev);
  };

  const setNewValue = (newVal) => {
    setValue(newVal);
    setPreviewActive(false);
    onClick(newVal);
  };

  const renderActionButton = () => {
    const btnContent = children || content;

    if (typeof btnContent === 'string') {
      return (
        <>
          <div className={`action ${className}`} onClick={handleClick}>
            {btnContent}
          </div>
          <If cond={withDikiContent}>
            <Button
              type="link"
              icon={<ArrowDownOutlined />}
              size="large"
              onClick={toggleDikiContent}
            />
          </If>
        </>
      );
    }

    return <div onClick={handleClick}>{btnContent}</div>;
  };

  const renderDikiPreview = () => {
    const result = [];
    for (const word of payload) {
      result.push(
        <div key={`name-${word.name}`}>
          <b>{word.name}</b>
          {word.otherNames ? ', ' + word.otherNames.join(',') : ''}
        </div>
      );
      if (word.addition) result.push(<div key={`add-${word.name}`}>{word.addition}</div>);
      for (const tran of word.translations || []) {
        result.push(
          <div key={`tran-${tran.translation.join(',')}`} style={{ display: 'flex', flexDirection: 'row' }}>
            ⚫{' '}
            {tran.translation.map((t, idx) => (
              <div key={idx} className="diki-translation" onClick={() => setNewValue(t)}>
                {t + ', '}
              </div>
            ))}
          </div>
        );
        for (const sentence of tran.sentences || []) {
          result.push(
            <div
              key={`sent-${sentence.value}`}
              className="diki-sentence"
              onClick={() => setNewValue(sentence.value)}
            >
              {tabComponent(2)}{' ' + sentence.value}
            </div>
          );
        }
      }
      for (const speech of word.speeches || []) {
        result.push(<div key={`speech-${speech.speechName}`}>{speech.speechName}</div>);
        for (const tran of speech.translations) {
          result.push(
            <div
              key={`speech-tran-${tran.translation.join(',')}`}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              ⚫{' '}
              {tran.translation.map((t, idx) => (
                <div key={idx} className="diki-translation" onClick={() => setNewValue(t)}>
                  {t + ', '}
                </div>
              ))}
            </div>
          );
          for (const sentence of tran.sentences || []) {
            result.push(
              <div
                key={`speech-sent-${sentence.value}`}
                className="diki-sentence"
                onClick={() => setNewValue(sentence.value)}
              >
                {tabComponent(2)}{' ' + sentence.value}
              </div>
            );
          }
          for (const t of tran.trans || []) {
            result.push(
              <div key={`speech-trans-${t}`} onClick={() => setNewValue(t)}>
                {tabComponent(1)}{'⚪ ' + t}
              </div>
            );
          }
        }
      }
    }

    return <div className="diki-preview">{result}</div>;
  };

  return (
    <div className="simple-form">
      <div className="simple-form-row">
        <input
          type="text"
          className="input-text"
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        {renderActionButton()}
      </div>
      <If cond={previewActive}>{renderDikiPreview()}</If>
    </div>
  );
}

export default SimpleForm;
