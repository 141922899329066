import React, { useState } from 'react';
import { Input, Button } from 'antd';
import {
  ForwardOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { makeCall } from '../utils';

const { TextArea } = Input;

function ImportView() {
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const createCard = async (things) => {
    const body = JSON.stringify({ type: 'card', name: 'english', things });
    await makeCall('PUT', body);
    setValue('');
  };

  const onImport = async () => {
    const things = value
      .split('\n')
      .map(line => line.trim())
      .filter(line => line)
      .map(line => line.replace(/\s+/g, ' '))
      .map(line => line.split(' - '))
      .filter(line => line.length < 3)
      .map(line => ({ word: line[0], translations: line[1] ? [line[1]] : [] }));

    await createCard(things);
    navigate('/');
  };

  return (
    <div className="container" style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        type="primary"
        icon={<RollbackOutlined />}
        size="large"
        onClick={() => navigate(-1)}
        style={{ height: 'auto' }}
      />
      <div style={{ display: 'flex', flex: 1 }}>
        <TextArea
          rows={4}
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ backgroundColor: '#272626', color: 'white' }}
        />
        <Button
          type="primary"
          icon={<ForwardOutlined />}
          size="large"
          onClick={onImport}
          style={{ height: 'auto' }}
        />
      </div>
    </div>
  );
}

export default ImportView;
